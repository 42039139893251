import React, { useContext } from "react";

import Stack from "@mui/material/Stack";
import { Formik, Form } from "formik";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess, alertInfo } from "@shared/Alerts";
import {
  renderButton,
  renderCancelButton,
  renderSelectField,
  renderSubmitButton,
  renderTextField,
  renderIntegerField
} from "@shared/FormUtils";
import { useAddTicketPackageType, useUpdateTicketPackageType } from "@shared/hooks/useTicketPackages";
import { useTicketTypes } from "@shared/hooks/useTickets";
import Loading from "@shared/Loading";

const TicketingPackageTypeForm = (props) => {
  const { packageType, cancel, callbackSuccess, callbackFailure } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const ticketTypes = useTicketTypes(apiRoot, event.id);
  const addTicketPackageType = useAddTicketPackageType(apiRoot, event.id);
  const updateTicketPackageType = useUpdateTicketPackageType(apiRoot, event.id);

  const isEdit = () => {
    return packageType;
  };

  const title = isEdit() ? `Edit ${packageType.name}` : "Create New Package";
  const buttonColor = isEdit() ? "primary" : "secondary";
  const buttonText = isEdit() ? "Save" : "Create";

  const alertIfNeeded = () => {
    if (packageType.total_assigned > 0) {
      alertInfo(
        `Warning: ${packageType.name} is currently assigned to participants. Modifying assigned tickets will not be reflected on those already assigned this package.`
      );
    }
  };

  const addTicketType = (ticketType, formikProps) => {
    const { values, setFieldValue } = formikProps;
    alertIfNeeded();
    let tickets = values["package_tickets"];
    tickets.push(ticketType);
    setFieldValue("package_tickets", tickets);
  };

  const removeTicketType = (ticketType, formikProps) => {
    const { values, setFieldValue } = formikProps;
    alertIfNeeded();
    let tickets = values["package_tickets"];
    let filteredTickets = tickets.filter((ticket) => ticket.id !== ticketType.id);
    setFieldValue("package_tickets", filteredTickets);
  };

  const packageTypeInitialValues = () => {
    if (!packageType) {
      return {};
    }

    delete packageType["total_assigned"];
    delete packageType["available_packages"];
    return packageType;
  };

  const ticketTypeInitialValues = () => {
    return packageType.package_tickets.map((pt) => pt.ticket_type);
  };

  const formValidation = (values) => {
    const errors = {};
    if (!values.package_type.name) {
      alert("You must enter a unique ticket name.");
      errors.package_type = errors.package_type || {};
      errors.package_type.name = "Required";
    }
    return errors;
  };

  const renderFormFields = (formikProps) => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-full">{renderTextField("Package Name", "package_type[name]", [], false, true)}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField(
                "Ticket Count",
                "package_type[total_count_type]",
                [
                  { value: "automatic", label: "Automatic (determined by tickets)" },
                  { value: "limited", label: "Limited" }
                ],
                false,
                true
              )}
            </div>
          </div>

          {formikProps.values.package_type.total_count_type == "limited" ? (
            <div className="flex">
              <div className="mr-4 w-full">
                {renderIntegerField(
                  "Total Available Packages",
                  "package_type[total_count]",
                  [],
                  0,
                  1,
                  10000000,
                  false,
                  true
                )}
              </div>
            </div>
          ) : (
              ""
            )}

          {renderCurrentTickets(formikProps)}
          {renderAvailableTickets(formikProps)}
        </div>
      </div>
    );
  };

  const renderCurrentTickets = (formikProps) => {
    const { values } = formikProps;
    return (
      <div className="flex">
        <div className="sg-mgmt-form-input-container sg-ticketing-package-form-available-tickets-container mr-4 w-full">
          <label>Assigned Tickets</label>
          {values["package_tickets"].map((ticketType) => renderCurrentTicketType(ticketType, formikProps))}
        </div>
      </div>
    );
  };

  const renderCurrentTicketType = (ticketType, formikProps) => {
    return (
      <div
        key={`sg-ticketing-${ticketType.id}`}
        className="sg-ticketing-package-form-available-tickets-container mb-2 mr-4 flex w-full items-center justify-between"
      >
        {ticketType.name}
        {renderCancelButton(
          "Remove",
          () => {
            removeTicketType(ticketType, formikProps);
          },
          {
            color: "error"
          }
        )}
      </div>
    );
  };

  const renderAvailableTickets = (formikProps) => {
    const { values } = formikProps;
    const availableTickets = ticketTypes.data.ticket_types.filter((ticket) => {
      let assignedTickets = values["package_tickets"];
      return !assignedTickets.filter((aTicket) => aTicket.id === ticket.id).length > 0;
    });
    return (
      <div className="flex">
        <div className="sg-mgmt-form-input-container sg-ticketing-package-form-available-tickets-container mr-4 w-full">
          <label>Available Tickets</label>
          {availableTickets.map((ticketType) => renderAvailableTicketType(ticketType, formikProps))}
        </div>
      </div>
    );
  };

  const renderAvailableTicketType = (ticketType, formikProps) => {
    return (
      <div
        key={`sg-ticketing-${ticketType.id}`}
        className="sg-ticketing-package-form-available-tickets-container mb-2 mr-4 flex w-full items-center justify-between"
      >
        {ticketType.name}
        {renderButton(
          "Add",
          () => {
            addTicketType(ticketType, formikProps);
          },
          {
            color: "secondary"
          }
        )}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          package_type: packageTypeInitialValues(),
          package_tickets: ticketTypeInitialValues()
        }}
        validate={formValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          const callbacks = {
            onSuccess: (data) => {
              if (data.error === null) {
                alertSuccess("Package Saved");
                callbackSuccess();
              } else {
                alertError(`Unable to save package: ${data.error}`);
                callbackFailure();
                setSubmitting(false);
              }
            },
            onError: (error) => {
              if (error?.response?.data?.error) {
                alertError(error.response?.data?.error);
              } else {
                alertHttpError(error);
              }
              setSubmitting(false);
            }
          };

          if (isEdit()) {
            updateTicketPackageType.mutate({ id: packageType.id, data: values }, callbacks);
          } else {
            addTicketPackageType.mutate({ data: values }, callbacks);
          }
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id="sg-mgmt-form-ticket-package">
            {renderFormFields(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(buttonText, isSubmitting, { color: buttonColor })}
        {renderCancelButton("Cancel", cancel, { color: buttonColor })}
      </Stack>
    );
  };

  if (ticketTypes.isLoading) {
    return <Loading />;
  }

  if (ticketTypes.isError) {
    return <div>Error: {ticketTypes.error}</div>;
  }

  return (
    <div className="sg-mgmt-modal-frame">
      <div className={`sg-mgmt-modal-title ${isEdit() ? "sg-mgmt-modal-title-edit" : ""}`}>{title}</div>
      <div className="sg-mgmt-modal-content">
        <div>{renderForm()}</div>
      </div>
    </div>
  );
};

export default TicketingPackageTypeForm;
