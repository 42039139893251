import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchTicketRequests = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/ticketing/ticket_requests")
  });
};

const deleteTicketRequest = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/ticketing/ticket_requests/${id}`)
  });
};

const ticketRequestApprove = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/ticketing/ticket_requests/${id}/approve`),
    data: data
  });
};

const ticketRequestDeny = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/ticketing/ticket_requests/${id}/deny`),
    data: data
  });
};


const updateTicketRequest = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/ticketing/ticket_requests/${id}`),
    data: data
  });
};

const updateTicketRequestCount = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/ticketing/ticket_requests/${id}/update_count`),
    data: data
  });
};

export const useTicketRequests = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["ticketRequests", eventId],
    queryFn: () => fetchTicketRequests(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useDeleteTicketRequest = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteTicketRequest(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketRequests", eventId]);
    }
  });
};

export const useTicketRequestApprove = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => ticketRequestApprove(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketRequests", eventId]);
    }
  });
};

export const useTicketRequestDeny = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => ticketRequestDeny(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketRequests", eventId]);
    }
  });
};

export const useUpdateTicketRequest = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateTicketRequest(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketRequests", eventId]);
    }
  });
};

export const useUpdateTicketRequestCount = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateTicketRequestCount(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketRequests", eventId]);
    }
  });
};
