import React, { useState } from "react";
import grapesjs from 'grapesjs';
import GjsEditor from '@grapesjs/react';
import WebpagePreset from 'grapesjs-preset-webpage';
import BlockPlugin from 'grapesjs-blocks-basic';
import TemplatePlugin from 'grapesjs-templates';
import { useField, useFormikContext } from "formik";
const HtmlBuilder = ( props ) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  const { options, onChange } = props;
  
  const onEditor = (editor) => {

    const removableBlocks = editor.BlockManager.blocks.models.filter(x=>
      x.attributes.category.attributes.id == "Short Codes"
    )
    
    removableBlocks.map(x=> 
      editor.BlockManager.remove(x.attributes.id)
    )
    editor.BlockManager.remove('custom-block-id')
    const value = field.value;
    if(value) {
      if(typeof value == "object") {
        editor.setComponents(field.value.components)
      }else {
        editor.setComponents({
          type: 'text',
           classes:['cls'],
           content: field.value
         });
      }
      
    }

    //TODO 
    editor.on('change:changesCount', e => {
      const returnableObject = {
        html: editor.getHtml(),
        css: editor.getCss(),
        components: editor.getComponents(),
      }
      setFieldValue(field.name, returnableObject);
      setTimeout(() => setFieldTouched(field.name, true));  
      if (onChange) {
        onChange(returnableObject);
      }
    });
  };

  return (
    <GjsEditor
      grapesjs={grapesjs}
      grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
      options={{
        storageManager: false,
        plugins: [
          WebpagePreset,
          BlockPlugin,
          TemplatePlugin
        ],
        pluginsOpts: {
          'grapesjs-templates': { 

          }
        },
        height: "600px",
        ...(options||{})
      }}
      onEditor={onEditor}
    />
  )
}

export default HtmlBuilder