import React from "react";

import Stack from "@mui/material/Stack";
import Modal from "react-modal";

import {
  renderCancelButton,
} from "@shared/FormUtils";

import TicketingPackageTypeHistory from "./TicketingPackageTypeHistory";

const TicketingPackageTypeHistoryModal = (props) => {
  const { packageTypeId, modalVisible, resetModal } = props;

  Modal.setAppElement("#root");

  const renderPackageHistory = () => {
    return (
      <TicketingPackageTypeHistory
        packageTypeId={packageTypeId}
      />
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Ticket Package History"
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Ticket History</div>
        <div className="sg-mgmt-modal-content">
          {modalVisible ? renderPackageHistory() : <></>}
          <Stack direction="row" spacing={2}>
            {renderCancelButton("Back", resetModal, { color: "secondary" })}
          </Stack>
        </div>
      </div>
    </Modal>
  );
};

export default TicketingPackageTypeHistoryModal;
