import React from "react";
import {
  renderHtmlEditorField,
  renderHtmlBuilderField,
  renderTextField,
  renderTextAreaField,
  renderRichTextAreaField,
  renderSelectFieldFilterable,
  renderCheckField,
  renderRadioField,
  renderDateField,
  renderCheckFieldForcedValue
} from "@shared/FormUtils";
import { Button } from "@mui/material";
const getReadableContentType = (type) => {
  const contentType = contentTypes.find(ct => ct.slug === type);
  return contentType ? contentType.name : type;
}

const getContentType = (type) => {
  const contentType = contentTypes.find(ct => ct.slug === type);
  return contentType ? contentType : null;
}

const contentTypes = [
  {
    name: "Template",
    slug: "template",
    use_template: true,
    disable_on_templates: true
  },
  {
    name: "Web Page Editor",
    slug: "web_page_editor",
  },
  {
    name: "HTML",
    slug: "html",
  },
  {
    name: "Raw HTML",
    slug: "raw_html",
  },
  {
    name: "CSS",
    slug: "css",
  },
  {
    name: "JSON",
    slug: "json",
  },
  {
    name: "JavaScript",
    slug: "javascript",
  },
  {
    name: "Text",
    slug: "text",
  },
  {
    name: "Text (Multiline)",
    slug: "multiline_text",
  },
];

//Please dont refactor this, will get used again in two other places
const renderContentByType = (formikProps, type, name, label, properties={}) => {
  switch (type) {
    case "html":
      return renderRichTextAreaField(label, name, [], {

      });
    case "raw_html":
      return renderHtmlEditorField(label,name,[],false, {

      });
    case "web_page_editor":
      return (
        renderHtmlBuilderField(label, name, ["w-full", "h-full"])
      )
    case "css":
      return renderHtmlEditorField(label,name,[],false, {
        fieldProperties: {
          placeholder: "Enter CSS",
          mode: "css",
        }
      });
    case "javascript":
      return renderHtmlEditorField(label,name,[],false, {
        fieldProperties: {
          placeholder: "Enter Javascript",
          mode: "javascript",
        }
      });
    case "json":
      return <>
        {
          renderHtmlEditorField(label,name,[],false, {
            fieldProperties: {
              placeholder: "Enter JSON",
              mode: "json",
            }
          })
        }
        <Button onClick={() => {
          try {
            formikProps.setFieldValue(name, JSON.stringify(JSON.parse(formikProps.values[name]), null, 2));
          }catch(e) {

          }
        }}> Format JSON</Button>
      </>
    case "text":
      return renderTextField(label, name, [], false, false, properties);
    case "multiline_text":
      return renderTextAreaField(label, name, [], false, properties);
    default:
      return renderTextField(label, name, [], false, false, properties);
  }
}

export {
  contentTypes,
  renderContentByType,
  getReadableContentType,
  getContentType
}