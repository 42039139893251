import React from "react";

import Modal from "react-modal";

import TicketingPackageTypeEdit from "./TicketingPackageTypeEdit";
import TicketingPackageTypeNew from "./TicketingPackageTypeNew";

const TicketingPackageTypeFormModal = (props) => {
  const { packageTypeId, modalVisible, resetModal, callbackFailure, callbackSuccess } = props;

  Modal.setAppElement("#root");

  const cancelButton = () => {
    resetModal();
  };

  const renderTicketingForm = () => {
    if (packageTypeId) {
      return (
        <TicketingPackageTypeEdit
          cancelButton={cancelButton}
          callbackFailure={callbackFailure}
          callbackSuccess={callbackSuccess}
          packageTypeId={packageTypeId}
        />
      );
    }

    return (
      <TicketingPackageTypeNew
        cancel={cancelButton}
        callbackSuccess={callbackSuccess}
        callbackFailure={callbackFailure}
      />
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Edit Ticket Type"
    >
      <div className="sg-mgmt-modal-frame">
        {modalVisible ? renderTicketingForm() : <></>}
      </div>
    </Modal>
  );
};

export default TicketingPackageTypeFormModal;
