import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { useTicketPackageType } from "@shared/hooks/useTicketPackages";
import Loading from "@shared/Loading";

import TicketingPackageTypeForm from "./TicketingPackageTypeForm";

const TicketingPackageTypeEdit = (props) => {
  const { cancelButton, callbackFailure, callbackSuccess, packageTypeId } = props;
  const { apiRoot, event } = useContext(EventContext).values;

  const packageType = useTicketPackageType(apiRoot, event.id, packageTypeId);

  if (packageType.isLoading) {
    return <div><Loading /></div>;
  }

  if (packageType.isError) {
    return <div>Error: {packageType.error}</div>;
  }

  return (
    <TicketingPackageTypeForm
      cancel={cancelButton}
      callbackSuccess={callbackSuccess}
      callbackFailure={callbackFailure}
      packageType={packageType.data.package_type}
    />
  );
}

export default TicketingPackageTypeEdit;
