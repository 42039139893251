import React from "react";

import TicketingTicketTypeForm from "./TicketingTicketTypeForm";

const TicketingTicketTypeNew = (props) => {
  const { cancelButton, callbackFailure, callbackSuccess } = props;

  return (
    <TicketingTicketTypeForm
      cancel={cancelButton}
      callbackSuccess={callbackSuccess}
      callbackFailure={callbackFailure}
    />
  );
};

export default TicketingTicketTypeNew;
