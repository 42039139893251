import React, { useContext, useRef, useState } from "react";

import { Checkbox } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import urljoin from "url-join";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";

import MicroformWidgetSettings from "./MicroformWidgetSettings";
import PeopleEventParticipantsModal from "../../../people/event_participants/PeopleEventParticipantsModal";

const RenderCheckBox = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  return (
    <Checkbox
      checked={value}
      value={value}
      variant="outlined"
      size="small"
      onChange={(e, newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
    />
  );
};

const RenderCheckBoxMetadata = (props) => {
  const { id, field, metadataField, row } = props;
  const apiRef = useGridApiContext();

  let values = "";
  if (row.metadata.find((md) => md.field_slug == metadataField.slug) !== undefined) {
    values = row.metadata.find((md) => md.field_slug == metadataField.slug).value;
  }

  return (
    <div className="w-full h-full overflow-y-scroll">
      {metadataField.options.map(option =>
        <div key={`metadata-${metadataField.gid}-option-${option.gid}`}>
          <Checkbox
            checked={values.includes(option.slug)}
            value={option.slug}
            variant="outlined"
            size="small"
            sx={{pl: 0, pt: 0, pb: 0}}
            onChange={(e, checked) => {
              let newValues = values.split(',').filter(item => item);
              if (checked) {
                newValues.push(option.slug);
              } else {
                newValues.splice(newValues.indexOf(option.slug), 1);
              }
              apiRef.current.setEditCellValue({ id, field, value: newValues.join(',') });
            }}
          />
          <label>{option.label}</label>
      </div>
      )}
    </div>
  );
};

const MicroformWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);
  const { apiRoot, rootUrl } = useContext(EventContext).values;
  const growlTableRef = useRef();
  const queryClient = useQueryClient();
  const enabled_metadata_fields = widget.widget_config.enabled_metadata_fields;
  const status_filter = widget.widget_config.microform.participantsByStatus;
  const [editParticipantId, setEditParticipantId] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const openAddModal = () => {
    setAddModalVisible(true);
  };

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const resetAddModal = () => {
    closeAddModal();
  };

  const filterParticipants = (participants) => {
    let filteredParticipants = participants;
    let statusesToKeep = [];
    Object.keys(status_filter).forEach((item) => {
      if (status_filter[item].selected) {
        statusesToKeep.push(item);
      }
    });
    filteredParticipants = filteredParticipants.filter((p) => statusesToKeep.includes(p.status) && !p.test_flag);
    return filteredParticipants;
  };

  const metaQuery = useQuery({
    queryKey: ["participantMeta"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/meta"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const participantQuery = useQuery({
    queryKey: ["participants"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/table"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const individualQuery = useQuery({
    queryKey: ["individuals"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(rootUrl, "/-/individuals"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const renderControls = () => {
    if (!editMode) return <></>;
    return <WidgetSettings label="Microform Settings" settingsComponent={MicroformWidgetSettings} />;
  };

  const addParticipant = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, participant) => {
      queryClient.setQueryData(["participants"], (existing) => {
        const newParticipants = [...existing.participants, participant];
        return { participants: newParticipants };
      });
    }
  });

  const updateParticipant = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, participant) => {
      queryClient.cancelQueries({ queryKey: ["participants"] });
      queryClient.setQueryData(["participants"], (existing) => {
        const newParticipants = existing.participants.map((p) => (p.gid === participant.gid ? participant : p));
        return { participants: newParticipants };
      });
    }
  });

  const renderCheckBox = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    return <Checkbox checked={value} value={value} variant="outlined" size="small" onChange={(e, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }} />
  }

  const renderMetadataDropdown = (mf) => {
    return {
      field: mf.slug,
      headerName: mf.label,
      editable: true,
      type: "singleSelect",
      valueOptions: mf.options.map((option) => { return { value: option.label, label: option.label } }),
      valueGetter: (params) => {
        const answer_value = params?.row?.metadata?.find((md) => md?.field_slug == mf.slug)?.value
        return answer_value === undefined ? "" : answer_value;
      },
      valueSetter: (params) => {
        if (params.row.metadata.find((md) => md.field_slug == mf.slug) !== undefined) {
          params.row.metadata.find((md) => md.field_slug == mf.slug).value = params.value;
        } else {
          params.row.metadata.push({ field_slug: mf.slug, value: params.value });
        }
        return params.row;
      }
    };
  }

  const renderMetadataCheckbox = (mf) => {
    return {
      field: mf.slug,
      headerName: mf.label,
      editable: true,
      renderEditCell: (params) => <RenderCheckBoxMetadata {...params} metadataField={mf} />,
      valueGetter: (params) => {
        const answer_value = params?.row?.metadata?.find((md) => md?.field_slug == mf.slug)?.value
        return answer_value === undefined ? "" : answer_value;
      },
      valueSetter: (params) => {
        if (params.row.metadata.find((md) => md.field_slug == mf.slug) !== undefined) {
          params.row.metadata.find((md) => md.field_slug == mf.slug).value = params.value;
        } else {
          params.row.metadata.push({ field_slug: mf.slug, value: params.value });
        }
        return params.row;
      }
    };
  }

  const columns = [
    {
      field: "override_name_first",
      headerName: "First Name",
      editable: true,
      valueGetter: (params) => {
        return params?.row?.name_first;
      },
      valueSetter: (params) => {
        params.row.name_first = params.value;
        return params.row;
      }
    },
    {
      field: "override_name_last",
      headerName: "Last Name",
      editable: true,
      valueGetter: (params) => {
        return params?.row?.name_last;
      },
      valueSetter: (params) => {
        params.row.name_last = params.value;
        return params.row;
      }
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200
    },
    {
      field: "override_company",
      headerName: "Company",
      editable: true,
      valueGetter: (params) => {
        return params?.row?.company;
      },
      valueSetter: (params) => {
        params.row.company = params.value;
        return params.row;
      }
    },
    {
      field: "type",
      headerName: "Participant Type",
      editable: true,
      type: "singleSelect",
      valueOptions: metaQuery.data.types.map((type) => type.name),
      valueGetter: (params) => {
        const str = params.value || "";
        return str;
      },
      valueSetter: (params) => {
        params.row.type = params.value;
        return params.row;
      }
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Preregistered", "Registered", "Cancelled", "Declined", "Disallowed"],
      valueGetter: (params) => {
        const str = params.value;
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
      valueSetter: (params) => {
        params.row.status = params.value;
        return params.row;
      }
    },
    {
      field: "attended",
      headerName: "Attended",
      editable: true,
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      },
      renderEditCell: (params) => (
        <RenderCheckBox {...params} />
      ),
      valueSetter: (params) => {
        params.row.attended = params.value;
        return params.row;
      }
    },
    metaQuery?.data?.metadataFields.flatMap((mf) => {
      switch(mf.field_format) {
        case "dropdown":
          return renderMetadataDropdown(mf);
        case "checkboxes":
          return renderMetadataCheckbox(mf);
        default:
          // code block
          return {
            field: mf.slug,
            headerName: mf.label,
            editable: true,
            valueGetter: (params) => {
              return params?.row?.metadata?.find((md) => md?.field_slug == mf.slug)?.value;
            },
            valueSetter: (params) => {
              if (params.row.metadata.find((md) => md.field_slug == mf.slug) !== undefined) {
                params.row.metadata.find((md) => md.field_slug == mf.slug).value = params.value;
              } else {
                params.row.metadata.push({ field_slug: mf.slug, value: params.value });
              }
              return params.row;
            }
          };
      }
    }),
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      minWidth: 180,
      getActions: (params) => [renderEditAction(params.row.id)]
    }
  ].flat();

  const defaultColumnVisibility = Object.fromEntries([
    ["vault_saved_at", false],
    ...metaQuery.data.metadataFields.map((mf) => [mf.slug, false])
  ]);

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const renderEditAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const filteredIndividuals = () => {
    const participantIndividualIds = participantQuery.data.participants.map((p) => p.individual_id);
    return individualQuery.data.individuals.filter((ind) => !participantIndividualIds.includes(ind.id));
  };

  const addIndividual = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, individual) => {
      queryClient.setQueryData(["individuals"], (existing) => {
        const newIndividuals = [...existing.individuals, individual];
        return { individuals: newIndividuals };
      });
    }
  });

  const addIndividualCallback = (individual) => {
    addIndividual.mutate(individual);
    refreshParticipants.mutate();
  };

  const refreshParticipants = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("participants");
    }
  });

  const renderAddModal = () => (
    <PeopleEventParticipantsModal
      apiRoot={apiRoot}
      closeModal={closeAddModal}
      addIndividual={addIndividualCallback}
      individuals={filteredIndividuals()}
      metadataFields={metaQuery.data.metadataFields}
      regFields={metaQuery.data.regFields}
      modalVisible={addModalVisible}
      resetModal={resetAddModal}
      rootUrl={rootUrl}
      tags={metaQuery.data.tags}
      types={metaQuery.data.types}
      updateFunc={(participant) => {
        addParticipant.mutate(participant);
      }}
      enabledMetadataFields={enabled_metadata_fields}
      isMicroform={true}
    />
  );

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsModal
        apiRoot={apiRoot}
        addIndividual={addIndividualCallback}
        enabledMetadataFields={enabled_metadata_fields}
        individuals={[]}
        isMicroform={true}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metaQuery.data.metadataFields}
        regFields={metaQuery.data.regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={metaQuery.data.tags}
        types={metaQuery.data.types}
        updateFunc={(participant) => {
          updateParticipant.mutate(participant);
        }}
      />
    );
  };

  const participantFields = ["attended", "override_name_first", "override_name_last", "override_company"];

  const saveCell = (params) => {
    let formUrl = urljoin(apiRoot, "/participants", `/${params.row.id}`);
    const formData = new FormData();
    formData.set("participant_gid", params.row.gid);
    if (participantFields.includes(params.field)) {
      formData.set(`participant[${params.field}]`, params.formattedValue);
    } else if (params.field === "status") {
      formData.set(`participant[${params.field}]`, params.formattedValue.toLowerCase());
    } else if (params.field === "type") {
      formData.set(
        `participant[event_participant_type_id]`,
        metaQuery.data.types.find((type) => type.name === params.formattedValue).id
      );
    } else {
      formData.set(`participant[metadata][${params.field}]`, params.formattedValue);
    }
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: formUrl,
      method: "PATCH",
      data: formData
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess(`Participant updated successfully`);
          updateParticipant.mutate(response.data.participant);
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertError(error);
      });
  };

  const renderAddParticipantButton = () => {
    if (widget?.widget_config?.displayAddParticipant === "true") {
      return renderCreateButton("Add Participant", openAddModal);
    }
    return <></>;
  };

  if (participantQuery.isPending || individualQuery.isPending || metaQuery.isPending) {
    return <Loading />;
  }

  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="mt-2">
        <div>{renderAddParticipantButton()}</div>
        <GrowlTable
          cellSelection={true}
          checkboxSelection
          onCellEditStop={saveCell}
          columns={columns}
          componentRef={growlTableRef}
          defaultColumnVisibility={defaultColumnVisibility}
          items={filterParticipants(participantQuery.data.participants)}
          microform={true}
          tableName={`${widget.gid}-participants`}
          toolbarControls={[]}
          pageSizeInitial={10}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
      {renderAddModal()}
      {renderEditModal()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default MicroformWidget;
