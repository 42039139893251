import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchTicketType = async (apiRoot, id) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, `/ticketing/ticket_types/${id}`)
  });
};

const fetchTicketTypes = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/ticketing/ticket_types")
  });
};

const addTicketType = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/ticketing/ticket_types`),
    data: data
  });
};

const deleteTicketType = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/ticketing/ticket_types/${id}`)
  });
};

const updateTicketType = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/ticketing/ticket_types/${id}`),
    data: data
  });
};

export const useTicketType = (apiRoot, eventId, id) => {
  return useQuery({
    queryKey: ["ticketTypes", eventId, id],
    queryFn: () => fetchTicketType(apiRoot, id),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useTicketTypes = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["ticketTypes", eventId],
    queryFn: () => fetchTicketTypes(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useAddTicketType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addTicketType(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketTypes", eventId]);
    }
  });
};

export const useDeleteTicketType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteTicketType(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketTypes", eventId]);
    }
  });
};

export const useUpdateTicketType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateTicketType(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketTypes", eventId]);
    }
  });
};
